import React from "react";
import HeaderTutoring from "../components/HeaderTutoring";
import TutoringInfo from "../components/TutoringInfo";
 
const Tutoring = () => {
    return (
    <div className="navbar-allowance">
        <HeaderTutoring />
        <TutoringInfo />
    </div>
    )
};
 
export default Tutoring;