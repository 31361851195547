import React from "react";
import HeaderAbout from "../components/HeaderAbout";
import WhoWeAre from "../components/WhoWeAre";
import Team from "../components/Team";
 
const About = () => {
    return (
        <div className="navbar-allowance">
            <HeaderAbout />
            <WhoWeAre />
            <Team />
        </div>
    );
};
 
export default About;