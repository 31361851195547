import React from 'react'
import {NavLink} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from "../assets/images/nav_logo.png"
import { ImFacebook } from "react-icons/im";
import { ImTwitter } from "react-icons/im";
import { ImLinkedin2 } from "react-icons/im";
import { ImInstagram } from "react-icons/im";

function Footer() {
  return (
    <section id="footer" class="bg-footer">
        <div class="row g-0 footer-all">
            <div class="col-12 col-md-4 footer-main d-none d-md-block">
                <img className="footer_logo" src={logo} />
            </div>
            <div class="col-12 col-md-4 footer-links d-none d-md-block">
                <h4>Useful Links</h4>
                    <NavLink eventKey="1" as={Link} to="/">Home</NavLink>
                    <NavLink eventKey="1" as={Link} to="about">About</NavLink>
                    <NavLink eventKey="1" as={Link} to="resources">Resources</NavLink>
                    <NavLink eventKey="1" as={Link} to="tutoring">Tutoring</NavLink>
                    <NavLink eventKey="1" as={Link} to="contact">Contact</NavLink>
            </div>
            <div class="col-12 col-md-4">
                <h4 className="footer-contact">Contact Us!</h4>
                <p className="footer-contact">info@studychunks.com</p>
                <a href="https://www.facebook.com/StudyChunks" target="_blank" class="facebook ms-3 p-1"><ImFacebook /></a>
                {/*
                <a href="https://x.com/studychunks" target="_blank" class="twitter ms-3 p-1"><ImTwitter /></a>
                <a href="https://www.instagram.com/studychunks_ig/" target="_blank" class="instagram ms-3 p-1"><ImInstagram /></a>
                */}
                <a href="https://www.linkedin.com/company/study-chunks" target="_blank" class="linkedin mx-3 p-1"><ImLinkedin2 /></a>
            </div>
        </div>
    </section>
  )
}

export default Footer