import React from "react";
import ContactForm from "../components/ContactForm";
import HeaderContact from "../components/HeaderContact";
 
const Contact = () => {
    return (
        <div className="navbar-allowance">
            <h1>
                <HeaderContact />
                <ContactForm />
            </h1>
        </div>
    );
};
 
export default Contact;