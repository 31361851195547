import React from 'react'
import { ImFacebook } from "react-icons/im";
import { ImTwitter } from "react-icons/im";
import { ImLinkedin2 } from "react-icons/im";
import { ImInstagram } from "react-icons/im";

function Socials() {
  return (
    <section id="socials">
        <div class="icon-bar d-none d-md-block">
            <a href="https://www.facebook.com/StudyChunks" target="_blank" class="facebook"><ImFacebook /></a>
            {/*
            <a href="https://x.com/studychunks" target="_blank" class="twitter"><ImTwitter /></a>
            <a href="https://www.instagram.com/studychunks_ig/" target="_blank" class="instagram"><ImInstagram /></a>
            */}
            <a href="https://www.linkedin.com/company/study-chunks" target="_blank" class="linkedin"><ImLinkedin2 /></a>
        </div>
    </section>
  )
}

export default Socials