import {Nav, Navbar, NavLink} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from "../assets/images/nav_logo.png"

function Navigationbar() {
    return (
        <Navbar className="navbar-main" fixed="top" collapseOnSelect expand="md" variant="light">
            <NavLink eventKey="1" as={Link} to="/"><img className="navbar_logo" src={logo} /></NavLink>
            <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav className="ms-auto">
                    <NavLink eventKey="1" as={Link} to="/">Home</NavLink>
                    <NavLink className="ps-md-5" eventKey="1" as={Link} to="about">About</NavLink>
                    <NavLink className="ps-md-5" eventKey="1" as={Link} to="resources">Resources</NavLink>
                    <NavLink className="ps-md-5" eventKey="1" as={Link} to="tutoring">Tutoring</NavLink>
                    <NavLink className="ps-md-5" eventKey="1" as={Link} to="contact">Contact</NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigationbar