import React from 'react'

function HeaderTutoring() {
  return (
    <section id="headertutoring" class="bg-hero">
        <div class="row g-0 hero-text">
            <div class="col-12">
                <img
                    className="hero-pic"
                    alt="image of hundreds of colourful books on a curved bookshelf"
                    src={require("../assets/images/header_tutoring.jpg")}
                /> 
            </div>
            <div class="col-12 text-on-image">
                <h2><strong>Tutoring</strong></h2>
            </div>
        </div>
    </section>
  )
}

export default HeaderTutoring