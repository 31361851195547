import React from 'react'

function HeaderContact() {
  return (
    <section id="headerabout" class="bg-hero">
        <div class="row g-0 hero-text">
            <div class="col-12">
                <img
                    className="headercontact-pic"
                    alt="puzzle pieces"
                    src={require("../assets/images/contact_pic.jpg")}
                /> 
            </div>
            <div class="col-12 text-on-image">
                <h2><strong>Contact Us</strong></h2>
            </div>
        </div>
    </section>
  )
}

export default HeaderContact