import React from 'react'

function Testimonials() {
  return (
    <section id="testimonials">
        <div class="row g-0 bg-testimonials py-5 quote">
            <div class="d-none d-lg-block col-12 col-lg-4 offset-lg-2 p-5">
                <h5>"This course was easy to understand and I now feel confident in using binary numbers"</h5>
            </div>
            <div class="col-12 col-lg-4 p-5">
                <h5>"Sarah is a great teacher and very patient. She has helped me to work through difficult topics"</h5>
            </div>
        </div>
    </section>
  )
}

export default Testimonials